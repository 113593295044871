@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400&family=Josefin+Sans:wght@400;700&display=swap');

body {
    font-family: 'Crimson Text', serif;
    margin: 0;
    padding: 0;
    background-color: #f7f4ef; /* Cream background for the page */
    color: #333;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    /* padding: 40px; */
    box-sizing: border-box;
}

.container {
    display: flex;
    height: 100vh;
    width: 100vw;
}

/* Left and right panels */
.text-panel, .content-panel {
    flex: 1;
    padding: 20px;
    background-color: #ffffff; /* White background for both panels */
    overflow-y: auto;
    color: #333;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Soft shadow for the text boxes */
    border-radius: 10px;
    margin: 10px;
    max-height: calc(100vh - 80px);
}

/* Story container */
.story-container, .section-content {
    font-size: 17px;
    line-height: 1.3;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: 'Crimson Text', serif;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    background-color: #ffffff; /* White background for the story panel */
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}


.story-text, .section-content {
    padding: 20px;
    margin-bottom: 20px;
    color: #333;
    background-color: #ffffff; 
    border: 1px solid #ddd;
    border-radius: 8px;
} 


.story-text p {
    margin: 1em 0;
    line-break: auto;
    line-height: 1.4;

}
/* Section title styling */
.section-title {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #555;
}

/* Links */
.scene-link {
    font-weight: bold;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
}

.scene-link:hover {
    color: #003366;
}

/* Book and play button styling */
.book-btn-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.book-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.book-image:hover {
    transform: scale(1.05);
}

.play-button {
    margin-top: 10px;
    background-color: #0066cc;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    font-family: 'Josefin Sans', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.play-button:hover {
    background-color: rgb(0, 128, 255);
    color: #fff;
}

/* Button panel styling */
.button-panel {
    display: flex;
    justify-content: center;
}

button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 8px;
    border: 2px solid #ddd;
    cursor: pointer;
    font-family: 'Josefin Sans', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: #e7e7e7;
    color: #333;
}

button:hover {
    background-color: #cccccc;
    color: #333;
}

#back-button {
    background-color: #e7e7e7;
    border-color: #cccccc;
}

#save-button {
    background-color: #e7e7e7;
    border-color: #cccccc;
}

#back-button:hover {
    background-color: #cccccc;
    color: #333;
}

#save-button:hover {
    background-color: #cccccc;
    color: #333;
}

/* Modal styling */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
    background-color: #ffffff;
    margin: 10% auto;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.close {
    color: #666;
    float: right;
    font-size: 24px;
    cursor: pointer;
}

.close:hover {
    color: #333;
}
.navbar {
    padding: 10px;
}
.navbar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.button-panel {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 10px;
}

.navigation-button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px;
    border: 2px solid #ddd;
    cursor: pointer;
    font-family: 'Josefin Sans', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: #e7e7e7;
    color: #333;
}

.navigation-button:hover {
    background-color: #cccccc;
    color: #333;
}

.text-size-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.text-size-button {
    margin: 0 5px;
    padding: 8px 12px;
    font-size: 18px;
    border-radius: 8px;
    border: 2px solid #ddd;
    cursor: pointer;
    font-family: 'Josefin Sans', sans-serif;
    background-color: #e7e7e7;
    color: #333;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.text-size-button:hover {
    background-color: #cccccc;
    color: #333;
}

.restart-button {
    margin-left: 10px;
    background-color: #7cbdff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    font-family: 'Josefin Sans', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.restart-button:hover {
    background-color: #990000;
    color: #fff;
}


#map-container {
    margin: 20px 0;
    text-align: center;
}

#map-image {
    max-width: 100%; /* Responsive */
    height: auto;
}



