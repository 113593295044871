body {
  font-family: Arial, sans-serif;
}

#story-container {
  padding: 20px;
}

.story-text {
  font-size: 17px;
  margin-bottom: 20px;
}
